import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Petit bolet de color blanquinós a groc de fins a 4 mm de diàmetre, el qual presenta la part superior en forma de disc, l’himeni llis i un peu llarg i prim. Les espores són irregularment fusiformes, llises i de 13-19 x 3-4 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      